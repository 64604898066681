define('m13/controllers/auflager', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    aTyp: 1,
    displayErrors: true,
    abreite: "",
    alaenge: "",
    ueberstand: "",
    abstandsmass: "0.0",
    streckenlast: false,
    auflagernaheeinzellast: false,
    abstandsmasstoggle: true,
    openFromFile: false,

    ttBreiteA: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.1 - 999.0 [cm]";
    }),
    ttLaengeA: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.1 - 999.0 [cm]";
    }),
    ttUeberstand: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.0 - 999.0 [cm]";
    }),
    ttAbstandsmass: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.0 - 999.0 [cm]";
    }),

    i18n: Ember['default'].inject.service(),

    validations: {
      abreite: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.0
        }
      },
      alaenge: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.0
        }
      },
      ueberstand: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0
        }
      },
      abstandsmass: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0
        }
      }
    },

    aTypen: [{
      name: "Endauflager",
      value: 1
    }, {
      name: "Zwischenauflager",
      value: 0
    }],

    setValues: function setValues(values) {

      // var self = this;
      //
      // if (self.debug) {
      //   console.log("values in auflager: ");
      //   console.log(values);
      // }
      //
      // var htbreiteEvent = {
      //   target: {
      //     name: "htbreite"
      //   }
      // };
      // var hthoeheEvent = {
      //   target: {
      //     name: "hthoehe"
      //   }
      // };
      // var ntbreiteEvent = {
      //   target: {
      //     name: "ntbreite"
      //   }
      // };
      // var nthoeheEvent = {
      //   target: {
      //     name: "nthoehe"
      //   }
      // };
      //
      // this.set('openFromFile', true);
      //
      // this.set('htholz', parseInt(values.MatKeyTimberElementHT));
      // this.set('htfkl', parseInt(values.FKLKeyTimberElementHT));
      //
      // this.set('htbreite', values.TimberElementHT_b);
      // this.setX3D(parseInt(values.TimberElementHT_b), htbreiteEvent);
      //
      // this.set('hthoehe', values.TimberElementHT_b);
      // this.setX3D(parseInt(values.TimberElementHT_b), hthoeheEvent);
      //
      // this.set('httraeger', values.HtRiegel);
      //
      // this.set('ntholz', parseInt(values.MatKeyTimberElementNT));
      // this.set('ntfkl', parseInt(values.FKLKeyTimberElementNT));
      //
      // this.set('ntbreite', values.TimberElementNT_b);
      // this.setX3D(parseInt(values.TimberElementHT_b), ntbreiteEvent);
      //
      // this.set('nthoehe', values.TimberElementNT_h);
      // this.setX3D(parseInt(values.TimberElementHT_b), nthoeheEvent);
      //
      // this.send('validation');
      //
      // this.set('openFromFile', false);

    },

    watchNumericalInputs: (function () {

      var self = this;

      if (!self.openFromFile) {

        if (self.get('abreite').indexOf(",") !== -1) {
          self.set('abreite', self.get('abreite').replace(",", "."));
        }

        if (self.get('alaenge').indexOf(",") !== -1) {
          self.set('alaenge', self.get('alaenge').replace(",", "."));
        }

        if (self.get('ueberstand').indexOf(",") !== -1) {
          self.set('ueberstand', self.get('ueberstand').replace(",", "."));
        }

        if (self.get('abstandsmass').indexOf(",") !== -1) {
          self.set('abstandsmass', self.get('abstandsmass').replace(",", "."));
        }
      }
    }).observes('abreite', 'alaenge', 'ueberstand', 'abstandsmass'),

    watchComboboxes: (function () {

      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);

      var auflager = this.controllerFor('application').get('model').auflager.objectAt(0);

      auflager.set('LagerTyp', this.get('aTyp'));
      auflager.set('LagerText', this.get('aTyp'));
    }).observes('aTyp'),

    watchCheckboxes: (function () {

      var self = this;

      var auflager = this.controllerFor('application').get('model').auflager.objectAt(0);

      auflager.set('Streckenlast', this.get('streckenlast'));
      auflager.set('Einzellast', this.get('auflagernaheeinzellast'));

      if (self.get('auflagernaheeinzellast')) {
        self.set('abstandsmasstoggle', false);
      } else {
        self.set('abstandsmasstoggle', true);
      }
    }).observes('streckenlast', 'auflagernaheeinzellast'),

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          //this.setX3D(value, event);

        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('auflagerInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);

            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);

            application.set('verbindungsmittelInvalid', true);

            var auflager = self.controllerFor('application').get('model').auflager.objectAt(0);

            auflager.set('bLager', parseFloat(self.get('abreite')).toFixed(1));
            auflager.set('lLager', parseFloat(self.get('alaenge')).toFixed(1));
            auflager.set('dl_Lager', parseFloat(self.get('ueberstand')).toFixed(1));
            auflager.set('L1', parseFloat(self.get('abstandsmass')).toFixed(1));
          } else {
            self.set('displayErrors', true);
            application.set('auflagerInvalid', true);
          }
        });
      }
    }

  });

});