define('m13/models/system', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var system = DS['default'].Model.extend({

		vmPosition: DS['default'].attr('string'),
		sFastener: DS['default'].attr('string'),
		AngleGRAD_Fasterner: DS['default'].attr('string'),
		Var_sVM: DS['default'].attr('string'),
		e0_VM: DS['default'].attr('string'),

		einschraubrichtungVonOben: DS['default'].attr('boolean'),

		ETA_friction: DS['default'].attr('boolean'),
		reibbeiwert: DS['default'].attr('string'),

		FastenerPosition: DS['default'].attr('string')

	});

	system.reopenClass({
		FIXTURES: [{
			id: 1,
			vmPosition: "2",
			sFastener: "0",
			AngleGRAD_Fasterner: "90",
			e0_VM: "0",
			Var_sVM: "0",

			einschraubrichtungVonOben: true,

			ETA_friction: false,
			reibbeiwert: "0.0"

		}]
	});

	exports['default'] = system;

});