define('m13/controllers/impressum', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    wuerth: false, //don't change this!
    swg: true, //don't change this!

    selectedLanguage: "DE",

    init: function init() {

      this._super();

      // var application = this.controllerFor('application');
      // this.set('wuerth', application.get('wuerth'));
      // this.set('swg', application.get('swg'));
    },

    spracheDeutsch: (function () {
      var self = this;

      if (self.get("selectedLanguage") === "DE") {
        return true;
      } else {
        return false;
      }
    }).property('selectedLanguage'),

    actions: {
      contactproduction: function contactproduction() {
        var part1 = "info";
        var part2 = Math.pow(2, 6);
        var part3 = String.fromCharCode(part2);
        var part4 = "swg-produktion.de";

        window.location.href = "mai" + "lto" + ":" + part1 + part3 + part4;
      },

      contactengineering: function contactengineering() {
        var part1 = "support.it";
        var part2 = Math.pow(2, 6);
        var part3 = String.fromCharCode(part2);
        var part4 = "swg-engineering.de";

        window.location.href = "mai" + "lto" + ":" + part1 + part3 + part4;
      }

    }

  });

});